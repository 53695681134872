export enum Slide {
  MyLearning,
  MyProfile,
  Learners,
  Leaderboard,
  ReportGenerator,
  TrendsAndAnalytics,
  Final,
}
export enum SlideHash {
  MyLearning = "my-learning",
  MyProfile = "my-profile",
  Learners = "learners",
  Leaderboard = "leaderboard",
  ReportGenerator = "report-generator",
  TrendsAndAnalytics = "trends-analytics",
  Final = "final",
}
export const getUrlHash: (page: Slide) => string = (page) => {
  switch (page) {
    case Slide.MyLearning:
      return SlideHash.MyLearning;
    case Slide.MyProfile:
      return SlideHash.MyProfile;
    case Slide.Learners:
      return SlideHash.Learners;
    case Slide.Leaderboard:
      return SlideHash.Leaderboard;
    case Slide.ReportGenerator:
      return SlideHash.ReportGenerator;
    case Slide.TrendsAndAnalytics:
      return SlideHash.TrendsAndAnalytics;
    case Slide.Final:
      return SlideHash.Final;
  }
};
