<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 28 24"
    fill="none"
  >
    <path
      d="M14 0C12.9481 0 12.0058 0.521693 11.4799 1.39547L0.394468 19.8136C-0.131489 20.6874 -0.131489 21.7307 0.394468 22.6045C0.920427 23.4783 1.86268 24 2.91459 24H25.0854C26.1373 24 27.0796 23.4783 27.6055 22.6045C28.1315 21.7307 28.1315 20.6874 27.6055 19.8136L16.5201 1.39547C15.9942 0.521693 15.0519 0 14 0ZM14 1.28813C14.5662 1.28813 15.0743 1.56937 15.3563 2.03954L26.4417 20.4576C26.726 20.9278 26.726 21.4903 26.4417 21.9605C26.1575 22.4306 25.6517 22.7119 25.0854 22.7119H2.91459C2.34835 22.7119 1.84029 22.4306 1.55829 21.9605C1.27405 21.4903 1.27405 20.9278 1.55829 20.4576L12.6437 2.03954C12.9279 1.56937 13.4338 1.28813 14 1.28813ZM14 7.68369C13.6285 7.68369 13.3286 7.97137 13.3286 8.32776V14.7684C13.3286 15.1248 13.6285 15.4125 14 15.4125C14.3715 15.4125 14.6714 15.1248 14.6714 14.7684V8.32776C14.6714 7.97137 14.3715 7.68369 14 7.68369ZM14 17.3447C13.6285 17.3447 13.3286 17.6323 13.3286 17.9887V19.0622C13.3286 19.4186 13.6285 19.7062 14 19.7062C14.3715 19.7062 14.6714 19.4186 14.6714 19.0622V17.9887C14.6714 17.6323 14.3715 17.3447 14 17.3447Z"
      fill="#171718"
    />
  </svg>
</template>
