<template>
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H5.99999C10.0041 0.75 13.25 3.99594 13.25 8V22C13.25 22.6904 12.6903 23.25 12 23.25H2C1.30964 23.25 0.75 22.6904 0.75 22V2Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <path
      d="M27.25 2C27.25 1.30964 26.6904 0.75 26 0.75H22C17.9959 0.75 14.75 3.99593 14.75 8V22C14.75 22.6904 15.3097 23.25 16 23.25H26C26.6904 23.25 27.25 22.6904 27.25 22V2Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
  </svg>
</template>
