import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { useAuthStore } from "@/stores/useAuthStore";

const baseAxiosConfig = (params: object = {}): AxiosRequestConfig => ({
  params,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
  // E.g. `?roles=XXX&roles=YYY&roles=ZZZ` rather than `?roles[]=XXX&roles[]=YYY&roles[]=ZZZ`
  // Config will be overwritten for post requests.
  paramsSerializer: { indexes: null },
});
const constructUrl = (url: string): string =>
  `${import.meta.env.VITE_MOON_HUB_WEB_API_URL}/api/${url}`;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
  if (config.headers?.["Authorization"] !== undefined) {
    // Don't overwrite the Authorization header if it has already been manually set for this request
    return config;
  }
  const authStore = useAuthStore();
  if (authStore.user != null && config.headers != undefined) {
    const token = authStore.user.jwtToken;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean; // Typescript will throw error unless we interface this
}

interface RequestQueueItem {
  resolve: (value: Promise<AxiosResponse> | AxiosResponse) => void;
  reject: (reason?: unknown) => void;
}

const requestQueue: Array<RequestQueueItem> = [];
let isRefreshing = false;

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const initialRequest = error.config as CustomAxiosRequestConfig;

    if (initialRequest._retry) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      console.log("Unauthorized error, attempting to refresh token...");

      if (isRefreshing) {
        console.log("A refresh is already in progress, queuing the request...");
        return new Promise<AxiosResponse>((resolve, reject) =>
          requestQueue.push({ resolve, reject }),
        );
      }

      isRefreshing = true;
      initialRequest._retry = true;

      const authStore = useAuthStore();
      try {
        console.log("Refreshing token...");
        await authStore.refreshToken();
        const token = authStore.user?.jwtToken;

        if (!token) {
          throw new Error("Token is null after refresh.");
        }

        // Typescript complaining object might be undefined
        initialRequest.headers = initialRequest.headers || {};

        if (!initialRequest?.headers?.Authorization) {
          throw new Error("Authorization header can't be initially null");
        }

        // Update the Authorization header for the original request
        initialRequest.headers.Authorization = `Bearer ${token}`;

        // Resolve all queued requests with the new token
        requestQueue.forEach(({ resolve }) => {
          console.log("Resolving queued request with new token...");
          resolve(axios(initialRequest));
        });
        requestQueue.length = 0; // Clear the queue
        return axios(initialRequest);
      } catch (e) {
        console.error("Error during token refresh:", e); // Log the refresh error
        // Reject all queued requests with the error
        requestQueue.forEach(({ reject }) => reject(e));
        requestQueue.length = 0; // Clear the queue
        throw e;
      } finally {
        isRefreshing = false;
      }
    }

    console.log("Rejecting request due to error:", error); // Log rejection
    return Promise.reject(error);
  },
);

const requests = {
  get: <T>(url: string, queryParams: object = {}) =>
    axios
      .get<T>(constructUrl(url), baseAxiosConfig(queryParams))
      .then(responseBody),
  post: <T>(
    url: string,
    body: object | object[],
    axiosConfig: AxiosRequestConfig | undefined = undefined,
  ) =>
    axios
      .post<T>(constructUrl(url), body, axiosConfig ?? baseAxiosConfig())
      .then(responseBody),
  put: <T>(url: string, body: object) =>
    axios.put<T>(constructUrl(url), body, baseAxiosConfig()).then(responseBody),
  delete: <T>(url: string, body: object | object[] | undefined = undefined) => {
    const config =
      body === undefined
        ? baseAxiosConfig()
        : { ...baseAxiosConfig(), data: body };
    return axios.delete<T>(constructUrl(url), config).then(responseBody);
  },
};

export { requests, baseAxiosConfig };
