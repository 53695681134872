import escapeRegex from "@/functional/escapeRegex";
import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";
import type { AuthedUserModel } from "@/models/authedUser.model";

const tracePropagationTarget = escapeRegex(
  import.meta.env.VITE_MOON_HUB_WEB_API_URL,
);
const tracePropogationTargetRegex = new RegExp(`^${tracePropagationTarget}/`);

const setupSentry = (app: App<Element>, router: Router) => {
  const sentryEnv = import.meta.env.VITE_ENVIRONMENT;
  const sentryReleaseName = import.meta.env.VITE_SENTRY__RELEASE;
  console.debug(
    `'${sentryEnv}' - '${sentryReleaseName}'. Trace target regex: '${tracePropogationTargetRegex}'`,
  );
  const enableSentry =
    sentryEnv !== undefined &&
    sentryEnv.length > 0 &&
    sentryEnv.toLowerCase() !== "development";
  if (!enableSentry) {
    console.log("Disabling Sentry during development");
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY__DSN,
    enabled: enableSentry,
    environment: sentryEnv,
    release: sentryReleaseName,
    logErrors: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Capture 10% of transactions for performance monitoring.
    tracesSampleRate: 0.2,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [tracePropogationTargetRegex],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

const setSentryUser = (user: AuthedUserModel | null) => {
  if (user === null) {
    Sentry.setUser(null);
    return;
  }

  Sentry.setUser({
    id: user.publicId,
    email: user.email,
    ip_address: "{{auto}}",
  });
};

export { setupSentry, setSentryUser };
