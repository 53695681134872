<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="7.25"
      width="6.73537"
      height="18"
      rx="1.25"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <rect
      x="10.6328"
      y="13.75"
      width="6.73537"
      height="11.5"
      rx="1.25"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <rect
      x="20.5146"
      y="0.75"
      width="6.73537"
      height="24.5"
      rx="1.25"
      stroke="#2A2730"
      stroke-width="1.5"
    />
  </svg>
</template>
