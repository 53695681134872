<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.08789 7.49023L1.08789 1.09289"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1.08789 28.9082L1.08789 22.1398"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1.17676 1.00024L28.9991 1"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1.17676 29H29.0001"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M28.999 28.9747V1.09341"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1.44141 14.8145L6.29915 10.2116"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M1.44141 14.8145L6.29915 19.4173"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M14.5049 14.6367L1.75003 14.6367"
      stroke="#2A2730"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
