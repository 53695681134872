<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 28 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="8" r="7.25" stroke="#2A2730" stroke-width="1.5" />
    <path
      d="M14 18.5195C21.3449 18.5195 26.2851 23.1495 27.2498 29.3031C27.2407 30.7301 26.8845 31.676 26.3279 32.3321C25.7565 33.0057 24.8958 33.4665 23.7152 33.7658C22.5312 34.066 21.09 34.1877 19.4303 34.2301C18.3111 34.2586 17.1244 34.251 15.8719 34.243C15.2634 34.2391 14.6394 34.2351 14 34.2351C13.3606 34.2351 12.7366 34.2391 12.1281 34.243C10.8756 34.251 9.68892 34.2586 8.56972 34.2301C6.90996 34.1877 5.46882 34.066 4.28482 33.7658C3.10418 33.4665 2.2435 33.0057 1.67212 32.3321C1.11548 31.676 0.759322 30.7301 0.75018 29.3031C1.7149 23.1495 6.65508 18.5195 14 18.5195Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
  </svg>
</template>
