<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.43742 0C7.06555 0 6.78117 0.284375 6.78117 0.65625V24.5C6.78117 24.8719 7.06555 25.1563 7.43742 25.1563C7.8093 25.1563 8.09367 24.8719 8.09367 24.5V13.5625H27.1249C27.4968 13.5625 27.7812 13.2781 27.7812 12.9063V0.65625C27.7812 0.284375 27.4968 0 27.1249 0H7.43742ZM26.4687 1.3125V3.0625V3.98128V1.3125ZM8.09367 7.4375V1.25128L17.2812 1.3125H24.7799H26.4687V3.98128V10.5H8.09367V7.4375ZM8.09367 10.5H26.4687V12.25H8.09367V10.5ZM13.9999 18.8125C12.7749 18.8125 11.5499 18.9 10.3468 19.075C9.9968 19.1188 9.7343 19.4688 9.79992 19.8188C9.84367 20.1688 10.1937 20.4313 10.5437 20.3656C11.6812 20.2125 12.8405 20.125 13.9999 20.125C18.5499 20.125 22.9687 21.4375 26.7749 23.9094C26.8843 23.975 27.0156 24.0188 27.1249 24.0188C27.3437 24.0188 27.5406 23.9094 27.6718 23.7125C27.8687 23.4063 27.7812 23.0125 27.4749 22.8156C23.4718 20.1906 18.8124 18.8125 13.9999 18.8125ZM4.35305 20.8316C4.26774 20.8338 4.17805 20.8513 4.09055 20.8906C2.84367 21.4375 1.64055 22.0938 0.503048 22.8375C0.196798 23.0344 0.109298 23.45 0.306173 23.7563C0.437423 23.9531 0.634298 24.0625 0.853048 24.0625C0.984298 24.0625 1.09367 24.0188 1.20305 23.9531C2.27492 23.2531 3.4343 22.6406 4.61555 22.1156C4.96555 21.9188 5.11867 21.5469 4.96555 21.2188C4.84961 20.9716 4.61336 20.825 4.35305 20.8316ZM12.9062 21.875C12.1821 21.875 11.5937 22.4634 11.5937 23.1875C11.5937 23.9116 12.1821 24.5 12.9062 24.5C13.6302 24.5 14.2187 23.9116 14.2187 23.1875C14.2187 22.4634 13.6302 21.875 12.9062 21.875ZM20.3437 22.9688C19.2565 22.9688 18.3749 23.8503 18.3749 24.9375C18.3749 26.0247 19.2565 26.9063 20.3437 26.9063C21.4309 26.9063 22.3124 26.0247 22.3124 24.9375C22.3124 23.8503 21.4309 22.9688 20.3437 22.9688Z"
      fill="#2A2730"
    />
  </svg>
</template>
