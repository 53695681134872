<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2257 6.5384C18.2257 8.50541 16.7383 10.0192 15.0001 10.0192C13.2618 10.0192 11.7744 8.50541 11.7744 6.5384C11.7744 4.57139 13.2618 3.05762 15.0001 3.05762C16.7383 3.05762 18.2257 4.57139 18.2257 6.5384Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <path
      d="M27.0552 3.33334C27.0552 4.78891 25.9294 5.91669 24.6038 5.91669C23.2781 5.91669 22.1523 4.78891 22.1523 3.33334C22.1523 1.87777 23.2781 0.75 24.6038 0.75C25.9294 0.75 27.0552 1.87777 27.0552 3.33334Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <path
      d="M7.84721 3.33334C7.84721 4.78891 6.72141 5.91669 5.39577 5.91669C4.07013 5.91669 2.94434 4.78891 2.94434 3.33334C2.94434 1.87777 4.07013 0.75 5.39577 0.75C6.72141 0.75 7.84721 1.87777 7.84721 3.33334Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <path
      d="M19.6915 20.0002H10.3086C9.75631 20.0002 9.30859 19.5525 9.30859 19.0002V15.749L9.9465 15.2292C12.8889 12.8316 17.1111 12.8316 20.0536 15.2292L20.6915 15.749V19.0002C20.6915 19.5525 20.2438 20.0002 19.6915 20.0002Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <path
      d="M27.7225 13H21.3541C20.8622 13 20.4434 12.6422 20.3664 12.1564L20 9.84218L20.228 9.69818C22.8375 8.05 26.1625 8.05 28.772 9.69818L29 9.84218L28.7147 12.1241C28.6522 12.6245 28.2268 13 27.7225 13Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
    <path
      d="M8.72245 13H2.35412C1.86221 13 1.44335 12.6422 1.36643 12.1564L1 9.84218L1.22798 9.69818C3.83754 8.05 7.16246 8.05 9.77202 9.69818L10 9.84218L9.71473 12.1241C9.65217 12.6245 9.22677 13 8.72245 13Z"
      stroke="#2A2730"
      stroke-width="1.5"
    />
  </svg>
</template>
