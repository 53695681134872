import { defineStore } from "pinia";
import type { AuthedUserModel } from "@/models/authedUser.model";
import type { Router } from "vue-router";
import type {
  UserLoginResponse,
  UserRefreshAccessTokenRequest,
} from "@/functional/api/apiUsers";
import apiUsers from "@/functional/api/apiUsers";
import parseJwt from "@/functional/auth/parseJwt";
import isJwtExpired from "@/functional/auth/isJwtExpired";
import { setSentryUser } from "@/setupSentry";
import { useToast } from "vue-toast-notification";
import apiOrganisations from "@/functional/api/apiOrganisations";
import type { OrganisationUserModel } from "@/models/organisationUser.model";

function isUserLoginResponse(obj: unknown): obj is UserLoginResponse {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const userObj = obj as UserLoginResponse;

  return (
    typeof userObj.publicId === "string" &&
    typeof userObj.firstName === "string" &&
    typeof userObj.lastName === "string" &&
    (typeof userObj.imageUrl === "string" || userObj.imageUrl === null) &&
    typeof userObj?.organisation?.publicId === "string" &&
    typeof userObj?.organisation?.name === "string" &&
    typeof userObj.organisation?.createdAt === "string" &&
    (typeof userObj?.organisation?.modifiedAt === "string" ||
      userObj?.organisation?.modifiedAt === null) &&
    typeof userObj.createdAt === "string" &&
    (typeof userObj.modifiedAt === "string" || userObj.modifiedAt === null) &&
    typeof userObj.email === "string" &&
    typeof userObj.emailConfirmed === "boolean" &&
    (typeof userObj.organisationUserName === "string" ||
      userObj.organisationUserName === null) &&
    (typeof userObj.managedBy === "string" ||
      userObj.managedBy === null ||
      userObj.managedBy === undefined) &&
    typeof userObj.jwtToken === "string" &&
    typeof userObj.userRefreshToken === "object" &&
    userObj.userRefreshToken !== null &&
    typeof userObj.userRefreshToken.token === "string"
  );
}

export interface AuthState {
  returnToUrl: string | null;
  currentUser: AuthedUserModel | null;
  currentUserPermissions: string[];
  queue: Array<() => Promise<unknown>>;
  isProcessing: boolean;
}

const localStorageAuthedUserSlug = "user";
const toast = useToast();

export const useAuthStore = defineStore({
  id: "auth",
  state: () =>
    ({
      returnToUrl: null,
      currentUser: null as AuthedUserModel | null,
      currentUserPermissions: [],
      queue: [],
      isProcessing: false,
    }) as AuthState,
  getters: {
    isAuthenticated: (state) => state.currentUser !== null,
    user: (state) => state.currentUser,
    permissions: (state) => state.currentUserPermissions,
  },
  actions: {
    async processQueue(): Promise<void> {
      if (this.queue.length === 0 || this.isProcessing) {
        return;
      }

      this.isProcessing = true;

      while (this.queue.length > 0) {
        const action = this.queue.shift();
        if (action) {
          await action();
        }
      }
      this.isProcessing = false;
    },
    addToQueue(action: () => Promise<unknown>): Promise<void> {
      this.queue.push(action);
      return this.processQueue();
    },
    async hydrateCurrentUserFromStorage(): Promise<void> {
      const storedUserStr = localStorage.getItem(localStorageAuthedUserSlug);

      if (storedUserStr) {
        try {
          const storedUser = JSON.parse(storedUserStr);

          if (isUserLoginResponse(storedUser)) {
            this.currentUser = storedUser;
            setSentryUser(this.currentUser);

            const token = storedUser.jwtToken;
            if (!token) {
              throw new Error("JWT token is missing");
            }

            const parsedToken = parseJwt(token);
            if (!parsedToken) {
              throw new Error("Failed to parse JWT token");
            }

            this.currentUserPermissions = parsedToken?.permission ?? [];

            if (isJwtExpired(parsedToken)) {
              console.warn("Token expired attempting refresh");
              await this.refreshToken();
            }
          } else {
            this.reset();
            throw new Error("Invalid user object in localStorage");
          }
        } catch (error) {
          console.error("Caught error during hydrating", error);
          this.reset();
          throw new Error("Failed to hydrate current user from storage.");
        }
      }
    },
    async login(
      email: string,
      password: string,
      router: Router,
    ): Promise<AuthedUserModel> {
      const user: UserLoginResponse = await apiUsers.login({
        email: email,
        password,
      });
      await this.setUserFromLoginResponse(user);
      await router.push(this.returnToUrl || "/");
      await this.checkIfOrgContractExpired(user);

      return user;
    },
    async checkIfOrgContractExpired(user: UserLoginResponse) {
      if (!user) return;

      try {
        const org = await apiOrganisations.get(user.organisation.publicId);
        const now = new Date();

        const gracePeriodDate = org?.gracePeriodEndAt
          ? new Date(org.gracePeriodEndAt)
          : null;
        const contractExpiryDate = org?.pricePlan?.contractExpiry
          ? new Date(org.pricePlan.contractExpiry)
          : null;

        if (
          contractExpiryDate &&
          gracePeriodDate &&
          !isNaN(contractExpiryDate.getTime()) &&
          !isNaN(gracePeriodDate.getTime()) &&
          contractExpiryDate > now &&
          gracePeriodDate < now
        ) {
          toast.info(
            `From ${gracePeriodDate.toLocaleDateString()}, your contract with MOONHUB will expire and you will no longer have access to training modules. Renew contract to continue using training.`,
            { position: "top-right", duration: 360000 },
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    async refreshToken(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.addToQueue(async () => {
          if (this.currentUser === null) {
            reject(console.error("Cannot refresh tokens for unset user"));
            return;
          }

          const refreshTokenBody = {
            refreshToken: this.currentUser.userRefreshToken.token,
            userPublicId: this.currentUser.publicId,
          } as UserRefreshAccessTokenRequest;

          try {
            const user = await apiUsers.refreshAccessToken(refreshTokenBody);
            await this.setUserFromLoginResponse(user);
            resolve(console.log("Refreshed token"));
          } catch (e) {
            this.reset();
            reject(console.error("Failed to refresh tokens", e));
          }
        });
      });
    },
    async organisationUserLogin(
      orgId: string,
      emailOrOrganisationUserName: string,
      password: string,
      router: Router,
    ): Promise<AuthedUserModel> {
      const user = await apiUsers.organisationLogin({
        organisationPublicId: orgId,
        emailOrOrganisationUserName,
        password,
      });
      await this.setUserFromLoginResponse(user);
      await router.push(this.returnToUrl || "/");

      return user;
    },
    async setUserFromLoginResponse(
      user: UserLoginResponse,
    ): Promise<AuthedUserModel> {
      if (!isUserLoginResponse(user)) {
        this.reset();
        throw new Error("Invalid UserLoginResponse object");
      }

      try {
        this.currentUser = user;
        setSentryUser(this.currentUser);
        localStorage.setItem(localStorageAuthedUserSlug, JSON.stringify(user));

        const token = user.jwtToken;

        if (!token) {
          throw new Error("JWT token is missing");
        }

        const parsedToken = parseJwt(token);
        if (!parsedToken) {
          throw new Error("Failed to parse JWT token");
        }

        this.currentUserPermissions = parsedToken?.permission ?? [];

        return this.currentUser;
      } catch (error) {
        this.reset();
        throw new Error("Failed to set user from login response");
      }
    },
    async updatePassword(
      token: string,
      singleUseCode: string,
      newPassword: string,
      router: Router,
    ): Promise<AuthedUserModel> {
      console.log("Update password...");
      const parsedToken = parseJwt(token);
      const userId = parsedToken?.["user-id"];
      console.log("userId:", userId);
      if (userId === undefined) {
        throw new Error("User Id not set");
      }
      const user = await apiUsers.updatePassword(
        userId,
        token,
        singleUseCode,
        newPassword,
      );
      await this.setUserFromLoginResponse(user);
      await router.push(this.returnToUrl || "/");

      return user;
    },
    updateCurrentUser(user: AuthedUserModel) {
      this.currentUser = user;
      setSentryUser(this.currentUser);
      localStorage.setItem(localStorageAuthedUserSlug, JSON.stringify(user));
    },
    updateCurrentUserAttributes(user: OrganisationUserModel) {
      if (
        user.publicId !== this.currentUser?.publicId ||
        this.currentUser === null
      ) {
        console.warn(
          `Not updating current user attributes due to an ID mismatch. Previously: ${this.currentUser?.publicId}, Updating to: ${user.publicId}`,
        );
        return;
      }
      console.log("Updating current user attributes");
      const newCurrentUser = {
        ...user,
        ...this.currentUser,
      } as UserLoginResponse;

      this.updateCurrentUser(newCurrentUser);
    },
    async revokeRefreshToken() {
      try {
        await apiUsers.revokeRefreshToken();
      } catch (error) {
        console.error(
          "An error occurred while revoking refresh token: ",
          error,
        );
      }
    },
    reset() {
      this.returnToUrl = null;
      this.currentUser = null;
      setSentryUser(null);
      this.currentUserPermissions = [];
      localStorage.removeItem(localStorageAuthedUserSlug);
    },
    async logout(router: Router) {
      await this.revokeRefreshToken();
      this.reset();
      await router.push("/login");
    },
  },
});
