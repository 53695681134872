<template>
  <div class="view-login">
    <router-view />
  </div>
</template>
<script lang="ts" setup></script>
<style scoped>
.view-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
