export interface JwtToken {
  exp: number;
  iat: number;
  nbf: number;
  permission: string[];
  "user-id": string;
}

export interface RawJwtToken {
  exp: number;
  iat: number;
  nbf: number;
  permission: string[] | string; // Claim may be a string in the case that it is the only permission returned
  "user-id": string;
}

export default (token: string): JwtToken | null => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    const rawToken = JSON.parse(jsonPayload) as RawJwtToken;
    if (typeof rawToken.permission === "string") {
      rawToken.permission = [rawToken.permission];
    }

    return rawToken as JwtToken;
  } catch (e) {
    return null;
  }
};
