import { requests } from "@/functional/api/api";
import isOffline from "@/functional/isOffline";
import chainSleep from "@/functional/chainSleep";
import type { UserModel } from "@/models/user.model";

export interface UserActionResponse {
  userPublicId: string;
  at: string;
}

export interface ListedOrganisationPricePlan {
  maxUsers: number;
  amountPerMonthInPence: number;
  contractStart: string;
  contractExpiry: string;
}

export interface ListedOrganisationResponse {
  // AccountManager not yet implemented on front or backend
  accountManager: null;
  deletionFirstConfirmedAt: UserActionResponse | null;
  deletionSecondConfirmedAt: UserActionResponse | null;
  deletionRequestedAt: UserActionResponse | null;
  gracePeriodEndAt: string | null;
  name: string;
  organisationStatus: string;
  pricePlan: ListedOrganisationPricePlan;
  publicId: string;
}

export interface ListOrganisationsResponse {
  averageContractPerMonthInPence: number;
  mrrInPence: number;
  organisationCount: number;
  organisations: ListedOrganisationResponse[];
}

let list = () => requests.get<ListOrganisationsResponse>("organisations");

if (isOffline) {
  list = () =>
    Promise.resolve({
      organisationCount: 2,
      mrrInPence: 0,
      averageContractPerMonthInPence: 0,
      organisations: [
        {
          publicId: "8a471b53-1d69-4506-ab67-3301b0f4f633",
          name: "MoonHub",
          pricePlan: {
            maxUsers: 10,
            amountPerMonthInPence: 20000,
            contractStart: "2018-12-10T05:00:00.428Z",
            contractExpiry: "2019-12-10T05:00:00.428Z",
          },
          gracePeriodEndAt: null,
          organisationStatus: "active",
          seatLimit: 20,
          amountPerMonthInPence: 0,
          accountManager: null,
          deletionRequestedAt: null,
          deletionFirstConfirmedAt: null,
          deletionSecondConfirmedAt: null,
        },
        {
          publicId: "8a471b53-1d69-4506-ab67-3301b0f4f634",
          name: "CHOAM",
          pricePlan: {
            maxUsers: 3,
            amountPerMonthInPence: 2,
            contractStart: "2018-12-10T05:00:00.428Z",
            contractExpiry: "2019-12-10T05:00:00.428Z",
          },
          gracePeriodEndAt: "2019-12-10T05:00:00.428Z",
          organisationStatus: "GracePeriod",
          seatLimit: 100,
          amountPerMonthInPence: 10500,
          accountManager: null,
          deletionRequestedAt: {
            userPublicId: "8a471b53-1d69-4506-ab67-3301b0f4f634",
            at: "2019-12-10T05:00:00.428Z",
          },
          deletionFirstConfirmedAt: {
            userPublicId: "8a471b53-1d69-4506-ab67-3301b0f4f635",
            at: "2019-13-10T05:00:00.428Z",
          },
          deletionSecondConfirmedAt: {
            userPublicId: "8a471b53-1d69-4506-ab67-3301b0f4f636",
            at: "2019-14-10T05:00:00.428Z",
          },
        },
      ],
    }).then(chainSleep(100, 3000));
}

export interface CreateOrganisationRequest {
  // Organisation info
  name: string;
  pricePlanReferencePublicId: string;
  maxUsers: number;
  amountPerMonthInPence: number;
  // Admin info
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  // Billing type
  manualPayments: boolean;
  // Invoice point of contact
  invoicePocFirstName: string;
  invoicePocLastName: string;
  invoicePocEmail: string;
  // Contract start date:
  contractStartDate: string;
  contractEndDate: string;
}

export interface CreateOrganisationResponse {
  publicId: string;
  name: string;
  createdAt: string;
  modifiedAt: string | null;
}

export interface GetOrganisationPricePlanResponse {
  publicId: string;
  maxUsers: number;
  amountPerMonthInPence: number;
  contractStart: string;
  contractExpiry: string;
  referencePricePlanPublicId: string;
  manualPayments: boolean;
}

export type OrganisationStatus = "GracePeriod" | "Active" | "Inactive";

export interface GetOrganisationResponse {
  publicId: string;
  name: string;
  gracePeriodEndAt: string | null;
  createdAt: string;
  modifiedAt: string | null;
  pricePlan: GetOrganisationPricePlanResponse;
  accountManagers: UserModel[];
  deletionRequestedAt: UserActionResponse | null;
  deletionFirstConfirmedAt: UserActionResponse | null;
  deletionSecondConfirmedAt: UserActionResponse | null;
  organisationStatus: OrganisationStatus;
}

let get: (orgId: string) => Promise<GetOrganisationResponse> = (orgId) =>
  requests.get(`organisations/${orgId}`);

if (isOffline) {
  get = (orgId) =>
    Promise.resolve({
      publicId: orgId,
      name: "",
      gracePeriodEndAt: null,
      createdAt: "2018-12-10T05:00:00.428Z",
      modifiedAt: null,
      pricePlan: {
        publicId: "8a471b53-1d69-4506-ab67-3301b0f4f633",
        maxUsers: 30,
        amountPerMonthInPence: 3000,
        contractStart: "2022-07-08T11:28:39.100655Z",
        contractExpiry: "2022-07-08T11:28:39.100655Z",
        referencePricePlanPublicId: "8a471b53-1d69-4506-ab67-3301b0f4f633",
        manualPayments: false,
      },
      accountManagers: [],
      deletionRequestedAt: null,
      deletionFirstConfirmedAt: null,
      deletionSecondConfirmedAt: null,
      organisationStatus: "Active" as OrganisationStatus,
    }).then(chainSleep(100, 3000));
}

let create: (
  request: CreateOrganisationRequest,
) => Promise<CreateOrganisationResponse> = (request) =>
  requests.post(`organisations`, request);

if (isOffline) {
  create = (request: CreateOrganisationRequest) =>
    Promise.resolve({
      publicId: "88f8389f-fc8c-4840-8152-b6531bc19d5d",
      name: request.name,
      createdAt: "2022-07-08T11:28:39.100655Z",
      modifiedAt: null,
      pricePlanPublicId: "00010203-0405-0607-0809-0a0b0c0d0e0f",
    }).then(chainSleep(100, 3000));
}

export interface UpdateOrganisationRequest {
  publicId: string;
  name: string;
  pricePlan: {
    maxUsers: number;
    amount: number;
    contractStart: string;
    contractExpiry: string;
    referencePricePlanPublicId: string;
  };
}

let update: (request: UpdateOrganisationRequest) => Promise<void> = (request) =>
  requests.put(`organisations/${request.publicId}`, request);

if (isOffline) {
  update = () => Promise.resolve().then(chainSleep(100, 3000));
}

let getStripeEmail: (orgId: string) => Promise<string> = (orgId) =>
  requests.get(`organisations/${orgId}/stripe-email`);

if (isOffline) {
  getStripeEmail = () =>
    Promise.resolve("email@themoonhub.com").then(chainSleep(100, 3000));
}
let updateStripeEmail: (orgId: string, email: string) => Promise<void> = (
  orgId,
  email,
) => requests.post(`organisations/${orgId}/set-stripe-email`, { email });

if (isOffline) {
  updateStripeEmail = () => Promise.resolve().then(chainSleep(100, 3000));
}

let setUserGroupAssociations: (
  orgId: string,
  userId: string,
  groupIds: string[],
) => Promise<void> = (orgId, userId, groupIds) =>
  requests.post(
    `organisations/${orgId}/users/${userId}/group-user-associations`,
    groupIds,
  );

if (isOffline) {
  setUserGroupAssociations = () =>
    Promise.resolve().then(chainSleep(100, 3000));
}

export default {
  create,
  get,
  list,
  update,
  updateStripeEmail,
  getStripeEmail,
  setUserGroupAssociations,
};
