<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 28 20"
    fill="none"
  >
    <path
      d="M10.64 0C9.71906 0 8.96 0.852161 8.96 1.88605V5.65815H1.68C0.759063 5.65815 0 6.51031 0 7.5442V19.3713C0 19.7176 0.251563 20 0.56 20H27.44C27.7484 20 28 19.7176 28 19.3713V10.0589C28 9.02505 27.2409 8.17289 26.32 8.17289H19.04V1.88605C19.04 0.852161 18.2809 0 17.36 0H10.64ZM10.64 1.25737H17.36C17.6706 1.25737 17.92 1.53733 17.92 1.88605V8.80157C17.92 9.14784 18.1716 9.43025 18.48 9.43025H26.32C26.6306 9.43025 26.88 9.71022 26.88 10.0589V18.7426H1.12V7.5442C1.12 7.19548 1.36938 6.91552 1.68 6.91552H9.52C9.82844 6.91552 10.08 6.6331 10.08 6.28684V1.88605C10.08 1.53733 10.3294 1.25737 10.64 1.25737Z"
      fill="#2A2730"
    />
    <path
      d="M13.8077 8V4.568L12.9977 5.066V4.166L13.8077 3.68H14.6417V8H13.8077Z"
      fill="#2A2730"
    />
    <path
      d="M3.48945 13.994V13.286L5.30145 11.678C5.43745 11.558 5.53345 11.445 5.58945 11.339C5.64545 11.233 5.67345 11.134 5.67345 11.042C5.67345 10.91 5.64645 10.793 5.59245 10.691C5.53845 10.587 5.46245 10.506 5.36445 10.448C5.26645 10.388 5.15145 10.358 5.01945 10.358C4.88145 10.358 4.75945 10.39 4.65345 10.454C4.54945 10.516 4.46845 10.599 4.41045 10.703C4.35245 10.805 4.32545 10.916 4.32945 11.036H3.48945C3.48945 10.74 3.55445 10.484 3.68445 10.268C3.81445 10.052 3.99545 9.885 4.22745 9.767C4.45945 9.649 4.72945 9.59 5.03745 9.59C5.31945 9.59 5.57145 9.652 5.79345 9.776C6.01745 9.898 6.19345 10.069 6.32145 10.289C6.44945 10.509 6.51345 10.764 6.51345 11.054C6.51345 11.268 6.48345 11.446 6.42345 11.588C6.36345 11.728 6.27445 11.858 6.15645 11.978C6.03845 12.096 5.89345 12.23 5.72145 12.38L4.55145 13.406L4.48545 13.226H6.51345V13.994H3.48945Z"
      fill="#2A2730"
    />
    <path
      d="M21.9075 16.078C21.6915 16.078 21.4855 16.035 21.2895 15.949C21.0935 15.861 20.9225 15.738 20.7765 15.58C20.6325 15.422 20.5295 15.236 20.4675 15.022L21.2595 14.812C21.3015 14.97 21.3835 15.093 21.5055 15.181C21.6295 15.267 21.7635 15.31 21.9075 15.31C22.0315 15.31 22.1445 15.28 22.2465 15.22C22.3505 15.16 22.4325 15.079 22.4925 14.977C22.5545 14.875 22.5855 14.762 22.5855 14.638C22.5855 14.452 22.5215 14.293 22.3935 14.161C22.2675 14.027 22.1055 13.96 21.9075 13.96C21.8475 13.96 21.7895 13.969 21.7335 13.987C21.6775 14.003 21.6235 14.026 21.5715 14.056L21.2055 13.414L22.5795 12.274L22.6395 12.448H20.6355V11.68H23.3355V12.448L22.2675 13.48L22.2615 13.252C22.5095 13.29 22.7185 13.377 22.8885 13.513C23.0605 13.647 23.1905 13.813 23.2785 14.011C23.3685 14.207 23.4135 14.416 23.4135 14.638C23.4135 14.91 23.3445 15.155 23.2065 15.373C23.0685 15.589 22.8855 15.761 22.6575 15.889C22.4295 16.015 22.1795 16.078 21.9075 16.078Z"
      fill="#2A2730"
    />
  </svg>
</template>
