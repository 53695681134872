import { createApp } from "vue";
import { createPinia } from "pinia";
import VueToast from "vue-toast-notification";
import App from "./App.vue";
import VCalendar from "v-calendar";
import router from "./router";
import FloatingVue from "floating-vue";

import "vue-toast-notification/dist/theme-sugar.css";
import "v-calendar/dist/style.css";
import "floating-vue/dist/style.css";
import { setupSentry } from "@/setupSentry";

const app = createApp(App);
app.use(createPinia()).use(router).use(VueToast);
app.use(VCalendar, {});
app.use(FloatingVue);

setupSentry(app, router);

app.mount("#app");
