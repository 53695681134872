export enum UserPermission {
  superAdmin = "super-admin",
  orgCreate = "org-create",
  orgEdit = "org-edit",
  orgList = "org-list",
  orgMakePayments = "org-make-payments",
  scoreList = "score-view",
  userEdit = "user-edit",
  userListUnder = "user-list-under",
  viewOrganisationUsers = "user-list",
  viewAnalytics = "view-analytics",
}
