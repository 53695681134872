import { useAuthStore } from "@/stores/useAuthStore";
import { onBeforeMount, ref } from "vue";
import hasAnyOfPermissionsFn from "@/functional/permissions/hasAnyOfPermissions";
import isSuperAdminFn from "@/functional/permissions/isSuperAdmin";
import isSuperAdminOrAdminFn from "@/functional/permissions/isSuperAdminOrAdmin";
import hasPermissionsFn from "@/functional/permissions/hasPermissions";

export default () => {
  const userStore = useAuthStore();
  const userPermissions = ref<string[]>([]);

  const hasAnyOfPermissions = (permissions: string[]): boolean => {
    return hasAnyOfPermissionsFn(userPermissions.value, permissions);
  };

  const hasPermissions = (permissions: string[]): boolean => {
    return hasPermissionsFn(userPermissions.value, permissions);
  };

  const isSuperAdmin = (): boolean => {
    return isSuperAdminFn(userPermissions.value);
  };

  const isSuperAdminOrAdmin = (): boolean => {
    return isSuperAdminOrAdminFn(userPermissions.value);
  };

  onBeforeMount(() => {
    userPermissions.value = userStore.permissions;
  });

  return {
    userPermissions,
    hasAnyOfPermissions,
    hasPermissions,
    isSuperAdmin,
    isSuperAdminOrAdmin,
  };
};
